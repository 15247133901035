import dayjs, { Dayjs } from "dayjs"
import MessageTypeEnum from "../enums/MessageTypeEnum"

export default class Message {

    public id: string
    public sent: boolean
    public content: string
    public title: string
    public receiverName: string
    public senderName: string
    public file: string
    public type?: MessageTypeEnum
    public createdAt: Dayjs

    constructor(data: any) {
        this.id = data.reference
        this.sent = data.sent
        this.receiverName = data.receiverName
        this.senderName = data.senderName
        this.content = data.content
        this.title = data.title
        this.file = data.file
        this.type = data.type ? MessageTypeEnum.tryParse(data.type) : undefined;
        this.createdAt = dayjs(data.createdAt)
    }
}
