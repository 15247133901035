import {Enumify} from "./Enumify";
import {formatDeathMemberStatusToForm} from "../helpers/businessHelper";

export default class DeathMemberStatusEnum extends Enumify<string> {
    static readonly paid = new DeathMemberStatusEnum('PAID');
    static readonly pending = new DeathMemberStatusEnum('PENDING');
    static readonly approved = new DeathMemberStatusEnum('APPROVED');
    static readonly accepted = new DeathMemberStatusEnum('ACCEPTED');
    static readonly rejected = new DeathMemberStatusEnum('REJECTED');
    static readonly cancelled = new DeathMemberStatusEnum('CANCELLED');
    static readonly cancelledByPlatform = new DeathMemberStatusEnum('CANCELLED_BY_PLATFORM');
    static readonly waitingRequiredDocuments = new DeathMemberStatusEnum('WAITING_REQUIRED_DOCUMENTS');
    static readonly _ = this.closeEnum();

    constructor(value: string) {
        super();
        this.value = value;
    }

    get translated() {
        return formatDeathMemberStatusToForm(this)
    }

    get statusMap() {
        switch (this) {
            case DeathMemberStatusEnum.pending:
                return { color: 'info' }
            case DeathMemberStatusEnum.approved:
            case DeathMemberStatusEnum.accepted:
            case DeathMemberStatusEnum.paid:
                return { color: 'primary' }
            case DeathMemberStatusEnum.cancelled:
            case DeathMemberStatusEnum.rejected:
                return { color: 'danger' }
            case DeathMemberStatusEnum.cancelledByPlatform:
                return { color: 'danger' }
            case DeathMemberStatusEnum.waitingRequiredDocuments:
            default:
                return { color: 'warning' }
        }
    }
}
