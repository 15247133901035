export const HOME = '/'

export const AUTH = {
    ROUTES_ENGINE: {
        LOGIN: 'auth/login',
        REGISTER: {
            INDEX: 'auth/register',
            SET_CREDENTIALS: 'auth/register/credentials',
        },
        FORGOT_PASSWORD: {
            INDEX: 'auth/forgot-password',
            RESET: 'auth/forgot-password/reset'
        }
    },
    LOGIN: '/auth/login',
    REGISTER: {
        INDEX: '/auth/register',
        SET_CREDENTIALS: '/auth/register/credentials',
    },
    FORGOT_PASSWORD: {
        INDEX: '/auth/forgot-password',
        RESET: '/auth/forgot-password/reset'
    }
}

export const CONDITIONS = {
    ROUTES_ENGINE: {
        CGU: 'general-terms-of-use',
        CONVENTION: 'convention',
    },
    CGU: '/general-terms-of-use',
    CONVENTION: '/convention',
}

export const ADVERTS = {
    ROUTES_ENGINE: {
        INDEX: 'adverts/*',
        DETAILS: ':id',
        UPDATE: ':id/update',
        CREATE: 'add',
    },
    INDEX: '/adverts',
    CREATE: '/adverts/add',
    DETAILS: '/adverts/:id',
    UPDATE: '/adverts/:id/update',
}

export const PARTNERS = {
    ROUTES_ENGINE: {
        INDEX: 'partners/*',
        CREATE: 'add',
    },
    INDEX: '/partners',
    CREATE: '/partners/add'
}

export const ARTICLES = {
    ROUTES_ENGINE: {
        INDEX: 'articles/*',
        DETAILS: ':id',
        UPDATE: ':id/update',
        CREATE: 'add',
    },
    INDEX: '/articles',
    CREATE: '/articles/add',
    DETAILS: '/articles/:id',
    UPDATE: '/articles/:id/update',
}

export const DASHBOARD = {
    ROUTES_ENGINE: {
        INDEX: 'dashboard',
        DOCUMENTS: 'docs/:id'
    },
    INDEX: '/dashboard',
    DOCUMENTS: '/docs/:id'
}

export const COMMUNITY = {
    ROUTES_ENGINE: {
        INDEX: 'communities/*',
        DETAILS: '/:id/details',
        UPDATE: '/update',
        MANAGERS: {
            INDEX: 'managers/*',
            CREATE: 'add',
            DETAILS: '/:id',
            UPDATE: '/:id/update',
        },
        MEMBERS: {
            INDEX: 'members/*',
            CREATE: 'add',
            ALL: 'all',
            DELETED: 'deleted',
            DETAILS: '/:id',
            UPDATE: '/:id/update',
        },
    },
    INDEX: '/communities',
    DETAILS: '/communities/:id/details',
    UPDATE: '/communities/update',
    MANAGERS: {
        INDEX: '/communities/managers',
        CREATE: '/communities/managers/add',
        DETAILS: '/communities/managers/:id',
        UPDATE: '/communities/managers/:id/update',
    },
    MEMBERS: {
        INDEX: '/communities/members',
        ALL: '/communities/members/all',
        DELETED: '/communities/members/deleted',
        CREATE: '/communities/members/add',
        DETAILS: '/communities/members/:id',
        UPDATE: '/communities/members/:id/update',
    },
}

export const FINANCES = {
    ROUTES_ENGINE: {
        INDEX: 'finances/*',
        CONTRIBUTIONS: {
            INDEX: 'contributions/*',
            ADMIN: 'admin',
            ADMIN_BY_EVENT: 'admin/:id',
        },
        WALLETS: {
            TRANSACTIONS: 'transactions/*',
        }
    },
    CONTRIBUTIONS: {
        INDEX: '/finances/contributions',
        ADMIN: '/finances/contributions/admin',
        ADMIN_BY_EVENT: '/finances/contributions/admin/:id',
    },
    WALLETS: {
        TRANSACTIONS: '/finances/transactions',
    }
}

export const FOUNDERS = {
    ROUTES_ENGINE: {
        INDEX: 'founders/*',
        DETAILS: ':id',
        UPDATE: ':id/update',
        CREATE: 'add',
    },
    INDEX: '/founders',
    CREATE: '/founders/add',
    DETAILS: '/founders/:id',
    UPDATE: '/founders/:id/update',
}

export const DEATHS = {
    ROUTES_ENGINE: {
        INDEX: 'deaths/*',
        DETAILS: ':id',
        DOCUMENT_TYPES: 'document-types',
        UPLOAD_DOCUMENTS: 'upload-documents'
    },
    INDEX: '/deaths',
    DETAILS: '/deaths/:id',
    DOCUMENT_TYPES: '/deaths/document-types',
    UPLOAD_DOCUMENTS: '/deaths/upload-documents'
}

export const TRANSFERS = {
    ROUTES_ENGINE: {
        INDEX: 'transfers/*',
        CREATE: 'new',
        DETAILS: ':id',
        UPDATE: ':id/update',
    },
    INDEX: '/transfers',
    CREATE: '/transfers/new',
    DETAILS: '/transfers/:id',
    UPDATE: '/transfers/:id/update',
}

export const TICKETS = {
    ROUTES_ENGINE: {
        INDEX: 'tickets/*',
        CREATE: 'new',
        DETAILS: ':id',
        UPDATE: ':id/update', 
        TYPES: {
            INDEX: 'types/*',
            CREATE: 'new',
            UPDATE: ':id/update',
        }
    },
    TYPES: {
        INDEX: '/tickets/types',
        CREATE: '/tickets/types/new',
        UPDATE: '/tickets/types/:id/update',
    },
    INDEX: '/tickets',
    CREATE: '/tickets/new',
    DETAILS: '/tickets/:id',
    UPDATE: '/tickets/:id/update',
}

export const EVENTS = {
    ROUTES_ENGINE: {
        INDEX: 'events/*',
        CREATE: 'new',
        EVENT_STAT: 'statistics/events',
        COMMUNITY_STAT: 'associations/events',
        DETAILS: ':id',
        DOCUMENTS: 'documents',
        UPDATE: ':id/update', 
        TYPES: {
            INDEX: 'types/*',
            CREATE: 'new',
            UPDATE: ':id/update',
        }
    },
    TYPES: {
        INDEX: '/events/types',
        CREATE: '/events/types/new',
        UPDATE: '/events/types/:id/update',
    },
    INDEX: '/events',
    CREATE: '/events/new',
    DOCUMENTS: '/events/documents',
    EVENT_STAT: '/events/statistics/events',
    COMMUNITY_STAT: '/events/associations/events',
    DETAILS: '/events/:id',
    UPDATE: '/events/:id/update',
}

export const POLLS = {
    ROUTES_ENGINE: {
        INDEX: 'polls/*',
        CREATE: 'create',
        DETAILS: ':id',
        UPDATE: '/:id/update',
    },
    INDEX: '/polls',
    CREATE: '/polls/create',
    DETAILS: '/polls/:id',
    UPDATE: '/polls/:id/update',
}

export const MESSAGES = {
    ROUTES_ENGINE: {
        INDEX: 'messages/*',
        CREATE: 'create',
        DETAILS: ':id'
    },
    INDEX: '/messages',
    CREATE: '/messages/create',
    DETAILS: '/messages/:id',
}

export const MISCELLANEOUS = {
    CGU: '/terms-of-service',
    CONVENTION: '/conventions',
}

export const SECURITY = {
    ROUTES_ENGINE: {
        INDEX: 'security/*',
        ROLES: {
            INDEX: 'roles/*'
        },
        PASSWORD: {
            INDEX: 'password/*'
        },
        PERMISSIONS: {
            INDEX: 'permissions/*'
        },
        SYSTEM_SETTINGS: {
            INDEX: 'system-settings/*'
        },
        USERS: {
            INDEX: 'users/*',
            ADD: 'add',
            DETAILS: ':id',
            UPDATE: ':id/update',
        }
    },
    ROLES: {
        INDEX: '/security/roles'
    },
    PASSWORD: {
        INDEX: '/security/password'
    },
    PERMISSIONS: {
        INDEX: '/security/permissions'
    },
    SYSTEM_SETTINGS: {
        INDEX: '/security/system-settings'
    },
    USERS: {
        INDEX: '/security/users',
        ADD: '/security/users/add',
        DETAILS: '/security/users/:id',
        UPDATE: '/security/users/:id/update',
    }
}

export const ERRORS = {
    ROUTES_ENGINE: {
        INDEX: 'errors/*',
        '403': '403',
        '404': '404',
    },
    '404': '/errors/404',
    '403': '/errors/403',
}
