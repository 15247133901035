import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import Dashboard from "../views/dashboard";
import * as FRONT from '../commons/urls/front'
import Permissions from '../commons/permissions';
import CanRoute from "../commons/permissions/CanRoute";

const LazySecurity = React.lazy(() => import("../views/security"))
const LazyCommunity = React.lazy(() => import("../views/communities"))
const LazyTransfers = React.lazy(() => import("../views/transfers"))
const LazyDeaths = React.lazy(() => import("../views/deaths"))
const LazyFinances = React.lazy(() => import("../views/finances"))
const LazyPolls = React.lazy(() => import("../views/polls"))
const LazyDocuments = React.lazy(() => import("../views/documents"))
const LazyArticles = React.lazy(() => import("../views/articles"))
const LazyFounders = React.lazy(() => import("../views/founders"))
const LazyTickets = React.lazy(() => import("../views/tickets"))
const LazyAdverts = React.lazy(() => import("../views/adverts"))
const LazyPartners = React.lazy(() => import("../views/partners"))
const LazyEvents = React.lazy(() => import("../views/events"))
const LazyMessages = React.lazy(() => import("../views/messages"))

const AuthRoutes = () => {
    return (
        <Routes>
            <Route
                path={FRONT.SECURITY.ROUTES_ENGINE.INDEX}
                element={(
                    <CanRoute
                        some
                        permissions={[
                            Permissions.security.roles,
                            Permissions.security.systemSettings,
                        ]}
                    >
                        <LazySecurity />
                    </CanRoute>
                )}
            />
            <Route
                path={FRONT.ARTICLES.ROUTES_ENGINE.INDEX}
                element={(
                    <CanRoute
                        some
                        permissions={[
                            Permissions.articles.member,
                        ]}
                    >
                        <LazyArticles />
                    </CanRoute>
                )}
            />
            <Route
                path={FRONT.FOUNDERS.ROUTES_ENGINE.INDEX}
                element={(
                    <CanRoute
                        some
                        permissions={[
                            Permissions.founders.member,
                        ]}
                    >
                        <LazyFounders />
                    </CanRoute>
                )}
            />
            <Route
                path={FRONT.ADVERTS.ROUTES_ENGINE.INDEX}
                element={(
                    <CanRoute
                        some
                        permissions={[
                            Permissions.adverts.member,
                        ]}
                    >
                        <LazyAdverts />
                    </CanRoute>
                )}
            />
            <Route
                path={FRONT.TICKETS.ROUTES_ENGINE.INDEX}
                element={(
                    <CanRoute
                        some
                        permissions={[
                            Permissions.tickets.member,
                            Permissions.tickets.types,
                        ]}
                    >
                        <LazyTickets />
                    </CanRoute>
                )}
            />
            <Route
                path={FRONT.EVENTS.ROUTES_ENGINE.INDEX}
                element={(
                    <CanRoute
                        some
                        permissions={[
                            Permissions.tickets.member,
                            Permissions.tickets.types,
                        ]}
                    >
                        <LazyEvents />
                    </CanRoute>
                )}
            />
            <Route
                path={FRONT.MESSAGES.ROUTES_ENGINE.INDEX}
                element={(
                    <CanRoute
                        some
                        permissions={[
                            Permissions.messages.member,
                            Permissions.messages.admin,
                        ]}
                    >
                        <LazyMessages />
                    </CanRoute>
                )}
            />
            <Route
                path={FRONT.DASHBOARD.ROUTES_ENGINE.DOCUMENTS}
                element={(
                    <CanRoute
                        some
                        permissions={[
                            Permissions.documents.member,
                            Permissions.documents.admin,
                        ]}
                    >
                        <LazyDocuments />
                    </CanRoute>
                )}
            />
            <Route
                path={FRONT.TRANSFERS.ROUTES_ENGINE.INDEX}
                element={(
                    <CanRoute some permissions={[ Permissions.transfers.member, Permissions.transfers.admin ]}>
                        <LazyTransfers />
                    </CanRoute>
                )}
            />
            <Route
                path={FRONT.FINANCES.ROUTES_ENGINE.INDEX}
                element={(
                    <CanRoute
                        some
                        permissions={[
                            Permissions.finances.member,
                            Permissions.finances.admin,
                        ]}
                    >
                        <LazyFinances />
                    </CanRoute>
                )}
            />
            <Route
                path={FRONT.DEATHS.ROUTES_ENGINE.INDEX}
                element={(
                    <CanRoute
                        some
                        permissions={[
                            Permissions.deaths.member,
                            Permissions.deaths.admin,
                        ]}>
                        <LazyDeaths />
                    </CanRoute>
                )}
            />
            <Route
                path={FRONT.POLLS.ROUTES_ENGINE.INDEX}
                element={(
                    <CanRoute
                        some
                        permissions={[
                            Permissions.polls.member,
                            Permissions.polls.admin,
                        ]}>
                        <LazyPolls />
                    </CanRoute>
                )}
            />
            <Route
                path={FRONT.PARTNERS.ROUTES_ENGINE.INDEX}
                element={(
                    <CanRoute
                        some
                        permissions={[
                            Permissions.partners
                        ]}>
                        <LazyPartners />
                    </CanRoute>
                )}
            />
            <Route
                path={FRONT.COMMUNITY.ROUTES_ENGINE.INDEX}
                element={(
                    <CanRoute
                        some
                        permissions={[
                            Permissions.members,
                            Permissions.managers,
                            Permissions.communities.members,
                        ]}
                    >
                        <LazyCommunity />
                    </CanRoute>
                )}
            />
            <Route
                path={FRONT.DASHBOARD.ROUTES_ENGINE.INDEX}
                element={<Dashboard />}
            />
            <Route path={FRONT.HOME} element={<Navigate to={FRONT.DASHBOARD.ROUTES_ENGINE.INDEX} replace />} />
            <Route path='*' element={<Navigate to={FRONT.DASHBOARD.ROUTES_ENGINE.INDEX} replace />} />
        </Routes>
    );
};

export default AuthRoutes;
