import {ReactElement} from "react"
import HomeIcon from "@mui/icons-material/Home"
import ShieldIcon from "@mui/icons-material/Shield"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import MultipleStopIcon from '@mui/icons-material/MultipleStop'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ViewListIcon from '@mui/icons-material/ViewList'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import ArticleIcon from '@mui/icons-material/Article'
import PaidIcon from '@mui/icons-material/Paid'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import DescriptionIcon from '@mui/icons-material/Description'
import GroupsIcon from '@mui/icons-material/Groups'
import Diversity3Icon from '@mui/icons-material/Diversity3'
import NewspaperIcon from '@mui/icons-material/Newspaper'

import Permissions from "../../../commons/permissions"
import * as FRONT from "../../../commons/urls/front"
import {joinUrlWithParamsId} from "../../../commons/helpers/funcHelper";
import DocumentTypeEnum from "../../../commons/enums/DocumentTypeEnum";
import { AutoAwesomeMotion, Church, Handshake, Handyman, Poll, Store, CalendarToday, BarChartOutlined, Message } from "@mui/icons-material"

export type MenuItem = {
    id: string,
    text?: string,
    path?: string,
    icon?: ReactElement,
    isDisable?: boolean,
    subMenu?: null | Record<string, MenuItem>,
    notification?: boolean | string,
    isMore?: boolean,
    hide: boolean,
    some?: boolean,
    isLine?: boolean,
    permissions?: Permissions[]
}

export const mainMenu: Record<'home', MenuItem> = {
    home: {
        id: 'home',
        text: 'home',
        path: FRONT.HOME,
        icon: <HomeIcon />,
        subMenu: null,
        hide: false
    }
}

export const communityMenu: Record<'communityLine' | 'community' | 'managers' | 'members' | 'allMembers' | 'communities' | 'transfers' | 'deaths' | 'communityUpdate' | 'events', MenuItem> = {
    communityLine: {
        isLine: true,
        id: 'community.line',
        some: true,
        permissions: [
            Permissions.managers,
            Permissions.members,
            Permissions.communities.self,
            Permissions.communities.members,
            Permissions.transfers.member,
            Permissions.transfers.admin,
            Permissions.deaths.admin
        ],
        hide: false
    },
    community: {
        id: 'community',
        text: 'community',
        some: true,
        permissions: [
            Permissions.managers,
            Permissions.members,
            Permissions.communities.self,
            Permissions.communities.members,
            Permissions.transfers.member,
            Permissions.transfers.admin,
        ],
        hide: false
    },
    communities: {
        id: 'communities',
        text: 'communities',
        path: FRONT.COMMUNITY.INDEX,
        icon: <GroupsIcon />,
        subMenu: null,
        some: true,
        permissions: [ Permissions.communities.self ],
        hide: false
    },
    communityUpdate: {
        id: 'community',
        text: 'communities.mine',
        path: FRONT.COMMUNITY.UPDATE,
        icon: <Diversity3Icon />,
        subMenu: null,
        permissions: [ Permissions.communities.update ],
        hide: false
    },
    members: {
        id: 'community.members',
        text: 'community.members',
        path: FRONT.COMMUNITY.MEMBERS.INDEX,
        icon: <PeopleAltIcon />,
        subMenu: null,
        some: true,
        permissions: [ Permissions.communities.members, Permissions.members ],
        hide: false
    },
    allMembers: {
        id: 'rpn.members',
        text: 'community.allMembers',
        path: FRONT.COMMUNITY.MEMBERS.ALL,
        icon: <PeopleAltIcon />,
        subMenu: null,
        some: true,
        permissions: [ Permissions.communities.members ],
        hide: false
    },
    managers: {
        id: 'community.managers',
        text: 'community.managers',
        path: FRONT.COMMUNITY.MANAGERS.INDEX,
        icon: <SupervisedUserCircleIcon />,
        subMenu: null,
        permissions: [ Permissions.managers ],
        hide: false
    },
    transfers: {
        id: 'transfers',
        text: 'transfers',
        path: FRONT.TRANSFERS.INDEX,
        icon: <MultipleStopIcon />,
        some: true,
        permissions: [ Permissions.transfers.member, Permissions.transfers.admin ],
        hide: false
    },
    deaths: {
        id: 'deaths',
        text: 'community.death',
        path: FRONT.DEATHS.INDEX,
        icon: <Church />,
        some: true,
        permissions: [ Permissions.deaths.admin ],
        subMenu: {
            deaths: {
                id: 'deaths.list',
                text: 'community.death.list.title',
                path: FRONT.DEATHS.INDEX,
                icon: <ViewListIcon />,
                subMenu: null,
                permissions: [
                    Permissions.deaths.admin,
                ],
                hide: false
            },
            deathDocumentTypes: {
                id: 'deaths.deathDocumentTypes',
                text: 'community.death.documentTypes.menu',
                path: FRONT.DEATHS.DOCUMENT_TYPES,
                icon: <ArticleIcon />,
                subMenu: null,
                permissions: [
                    Permissions.deaths.admin,
                ],
                hide: false
            },
        },
        hide: false
    },
    events: {
        id: 'events',
        text: 'business.event.management.title',
        path: FRONT.EVENTS.INDEX,
        icon: <CalendarToday />,
        subMenu: {
            list: {
                id: 'events.list',
                text: 'business.event.title',
                path: FRONT.EVENTS.INDEX,
                icon: <ViewListIcon />,
                subMenu: null,
                some: true,
                permissions: [ Permissions.events.admin, Permissions.events.member ],
                hide: false
            },
            types: {
                id: 'events.types',
                text: 'business.event.type.title',
                path: FRONT.EVENTS.TYPES.INDEX,
                icon: <AutoAwesomeMotion />,
                subMenu: null,
                permissions: [ Permissions.events.admin ],
                hide: false
            },
            documents: {
                id: 'events.documents',
                text: 'community.death.documentTypes',
                path: FRONT.EVENTS.DOCUMENTS,
                icon: <ArticleIcon />,
                subMenu: null,
                permissions: [ Permissions.events.admin ],
                hide: false
            },
            stats: {
                id: 'events.statistics',
                text: 'event.statistic',
                path: FRONT.EVENTS.EVENT_STAT,
                icon: <BarChartOutlined />,
                subMenu: null,
                permissions: [ Permissions.events.admin ],
                hide: false
            },
            stats2: {
                id: 'events.statistics2',
                text: 'event.community.statistic',
                path: FRONT.EVENTS.COMMUNITY_STAT,
                icon: <BarChartOutlined />,
                subMenu: null,
                permissions: [ Permissions.events.admin ],
                hide: false
            }
        },
        some: true,
        permissions: [ Permissions.events.admin, Permissions.events.member ],
        hide: false
    },
}

export const deathsAdmin: Record<'deathsLine' | 'deathsWrapper' | 'deaths' | 'deathDocumentTypes', MenuItem> = {
    deathsLine: {
        isLine: true,
        id: 'deaths.line',
        some: true,
        permissions: [
            Permissions.deaths.admin,
        ],
        hide: false
    },
    deathsWrapper: {
        id: 'deaths.wrapper',
        text: 'community.death',
        permissions: [
            Permissions.deaths.admin,
        ],
        hide: false
    },
    deaths: {
        id: 'deaths.list',
        text: 'community.death',
        path: FRONT.DEATHS.INDEX,
        icon: <Church />,
        subMenu: null,
        permissions: [
            Permissions.deaths.admin,
        ],
        hide: false
    },
    deathDocumentTypes: {
        id: 'deaths.deathDocumentTypes',
        text: 'community.death.documentTypes.menu',
        path: FRONT.DEATHS.DOCUMENT_TYPES,
        icon: <ArticleIcon />,
        subMenu: null,
        permissions: [
            Permissions.deaths.admin,
        ],
        hide: false
    },
}

export const deaths: Record<'deaths', MenuItem> = {
    deaths: {
        id: 'deaths.list',
        text: 'community.death',
        path: FRONT.DEATHS.INDEX,
        icon: <Church />,
        subMenu: null,
        permissions: [
            Permissions.deaths.member,
        ],
        hide: false
    },
}

export const finances: Record<'financesLine' | 'financesWrapper' | 'contributions' | 'contributionsAdmin' | 'transactions', MenuItem> = {
    financesLine: {
        isLine: true,
        id: 'finances.line',
        some: true,
        permissions: [
            Permissions.finances.member,
            Permissions.finances.admin,
            Permissions.deaths.member
        ],
        hide: false
    },
    financesWrapper: {
        id: 'finances.wrapper',
        text: 'finances',
        some: true,
        permissions: [
            Permissions.finances.member,
            Permissions.finances.admin
        ],
        hide: false
    },
    contributions: {
        id: 'finances.contributions.list',
        text: 'finances.contributions.list.title',
        path: FRONT.FINANCES.CONTRIBUTIONS.INDEX,
        icon: <PaidIcon />,
        subMenu: null,
        permissions: [
            Permissions.finances.member,
        ],
        hide: false
    },
    contributionsAdmin: {
        id: 'finances.contributionsAdmin.list',
        text: 'finances.contributions.list.title',
        path: FRONT.FINANCES.CONTRIBUTIONS.ADMIN,
        icon: <PaidIcon />,
        subMenu: null,
        permissions: [
            Permissions.finances.admin,
        ],
        hide: false
    },
    transactions: {
        id: 'finances.transactions.list',
        text: 'finances.transactions.list.title',
        path: FRONT.FINANCES.WALLETS.TRANSACTIONS,
        icon: <CurrencyExchangeIcon />,
        subMenu: null,
        permissions: [
            Permissions.finances.member,
        ],
        hide: true
    },
}

export const securityMenu: Record<'securityLine' | 'security' | 'roles' | 'systemSettings' | 'deletedMembers' | 'tickets', MenuItem> = {
    securityLine: {
        id: 'security.line',
        isLine: true,
        some: true,
        permissions: [
            Permissions.security.roles,
            Permissions.security.systemSettings,
            Permissions.tickets.member,
            Permissions.tickets.types,
            Permissions.communities.members,
            Permissions.members
        ],
        hide: false
    },
    security: {
        id: 'security',
        text: 'security',
        some: true,
        permissions: [
            Permissions.security.roles,
            Permissions.security.systemSettings,
            Permissions.tickets.member,
            Permissions.tickets.types,
            Permissions.communities.members,
            Permissions.members
        ],
        hide: false
    },
    tickets: {
        id: 'tickets',
        text: 'business.tickets.management.title',
        path: FRONT.TICKETS.INDEX,
        icon: <Handyman />,
        subMenu: {
            list: {
                id: 'tickets.list',
                text: 'business.tickets.list.title',
                path: FRONT.TICKETS.INDEX,
                icon: <ViewListIcon />,
                subMenu: null,
                some: true,
                permissions: [ Permissions.tickets.member, Permissions.tickets.types ],
                hide: false
            },
            settings: {
                id: 'tickets.types',
                text: 'business.tickets.types.title',
                path: FRONT.TICKETS.TYPES.INDEX,
                icon: <AutoAwesomeMotion />,
                subMenu: null,
                permissions: [ Permissions.tickets.types ],
                hide: false
            }
        },
        some: true,
        permissions: [ Permissions.tickets.member, Permissions.tickets.types ],
        hide: false
    },
    deletedMembers: {
        id: 'security.deletedMembers',
        text: 'community.members.deleted',
        path: FRONT.COMMUNITY.MEMBERS.DELETED,
        icon: <DeleteForeverIcon />,
        subMenu: null,
        some: true,
        permissions: [ Permissions.communities.members, Permissions.members ],
        hide: false
    },
    roles: {
        id: 'security.roles',
        text: 'security.roles',
        path: FRONT.SECURITY.ROLES.INDEX,
        icon: <ShieldIcon />,
        subMenu: null,
        permissions: [ Permissions.security.roles ],
        hide: false
    },
    systemSettings: {
        id: 'security.systemSettings',
        text: 'security.systemSettings.title.short',
        path: FRONT.SECURITY.SYSTEM_SETTINGS.INDEX,
        icon: <SettingsSuggestIcon />,
        subMenu: null,
        permissions: [ Permissions.security.systemSettings ],
        hide: false
    }
}

export const otherMenu: Record<'otherLine' | 'other' | 'polls' | 'messages' | 'articles' | 'adverts' | 'partners' | 'founders' | 'documents', MenuItem> = {
    otherLine: {
        id: 'other.line',
        isLine: true,
        some: true,
        permissions: [
            Permissions.polls.member,
            Permissions.polls.admin,
            Permissions.articles.member,
            Permissions.adverts.member,
            Permissions.partners,
            Permissions.founders.member,
            Permissions.documents.member,
            Permissions.documents.admin,
            Permissions.messages.admin
        ],
        hide: false
    },
    other: {
        id: 'other',
        text: 'general.others',
        some: true,
        permissions: [
            Permissions.polls.member,
            Permissions.polls.admin,
            Permissions.articles.member,
            Permissions.adverts.member,
            Permissions.partners,
            Permissions.founders.member,
            Permissions.documents.member,
            Permissions.documents.admin,
            Permissions.messages.admin
        ],
        hide: false
    },
    messages: {
        id: 'message',
        text: 'messages',
        path: FRONT.MESSAGES.INDEX,
        icon: <Message />,
        subMenu: null,
        some: true,
        permissions: [ Permissions.messages.admin ],
        hide: false
    },
    polls: {
        id: 'polls',
        text: 'polls',
        path: FRONT.POLLS.INDEX,
        icon: <Poll />,
        subMenu: null,
        some: true,
        permissions: [ Permissions.polls.member, Permissions.polls.admin ],
        hide: false
    },
    articles: {
        id: 'articles',
        text: 'business.articles.list.title',
        path: FRONT.ARTICLES.INDEX,
        icon: <NewspaperIcon />,
        subMenu: null,
        permissions: [ Permissions.articles.member ],
        hide: false
    },
    adverts: {
        id: 'adverts',
        text: 'business.adverts',
        path: FRONT.ADVERTS.INDEX,
        icon: <Store />,
        subMenu: null,
        permissions: [ Permissions.adverts.member ],
        hide: false
    },
    partners: {
        id: 'partners',
        text: 'business.partners',
        path: FRONT.PARTNERS.INDEX,
        icon: <Handshake />,
        subMenu: null,
        permissions: [ Permissions.partners ],
        hide: false
    },
    founders: {
        id: 'founders',
        text: 'business.founders.list.title',
        path: FRONT.FOUNDERS.INDEX,
        icon: <Diversity3Icon />,
        subMenu: null,
        permissions: [ Permissions.founders.member ],
        hide: false
    },
    documents: {
        id: 'business.documents.type.menu',
        text: 'business.documents.type.menu',
        path: FRONT.DASHBOARD.DOCUMENTS,
        icon: <DescriptionIcon />,
        some: true,
        permissions: [
            Permissions.documents.member,
            Permissions.documents.admin
        ],
        subMenu: {
            generalAssemblyReport: {
                id: 'business.documents.type.generalAssemblyReport.list.title',
                text: 'business.documents.type.generalAssemblyReport.list.title',
                path: joinUrlWithParamsId(FRONT.DASHBOARD.DOCUMENTS, DocumentTypeEnum.generalAssemblyReport.enumKey),
                icon: <ArticleIcon />,
                subMenu: null,
                hide: false
            },
            financeReport: {
                id: 'business.documents.type.financeReport.list.title',
                text: 'business.documents.type.financeReport.list.title',
                path: joinUrlWithParamsId(FRONT.DASHBOARD.DOCUMENTS, DocumentTypeEnum.financeReport.enumKey),
                icon: <ArticleIcon />,
                subMenu: null,
                hide: false
            },
            auditReport: {
                id: 'business.documents.type.auditReport.list.title',
                text: 'business.documents.type.auditReport.list.title',
                path: joinUrlWithParamsId(FRONT.DASHBOARD.DOCUMENTS, DocumentTypeEnum.auditReport.enumKey),
                icon: <ArticleIcon />,
                subMenu: null,
                hide: false
            },
            otherReport: {
                id: 'business.documents.type.otherReport.list.title',
                text: 'business.documents.type.otherReport.list.title',
                path: joinUrlWithParamsId(FRONT.DASHBOARD.DOCUMENTS, DocumentTypeEnum.otherReport.enumKey),
                icon: <ArticleIcon />,
                subMenu: null,
                hide: false
            },
            funeral: {
                id: 'business.documents.type.funeral.list.title',
                text: 'business.documents.type.funeral.list.title',
                path: joinUrlWithParamsId(FRONT.DASHBOARD.DOCUMENTS, DocumentTypeEnum.funeral.enumKey),
                icon: <ArticleIcon />,
                subMenu: null,
                hide: false
            },
            communication: {
                id: 'business.documents.type.communication.list.title',
                text: 'business.documents.type.communication.list.title',
                path: joinUrlWithParamsId(FRONT.DASHBOARD.DOCUMENTS, DocumentTypeEnum.communication.enumKey),
                icon: <ArticleIcon />,
                subMenu: null,
                hide: false
            },
            constitution: {
                id: 'business.documents.type.constitution.list.title',
                text: 'business.documents.type.constitution.list.title',
                path: joinUrlWithParamsId(FRONT.DASHBOARD.DOCUMENTS, DocumentTypeEnum.constitution.enumKey),
                icon: <ArticleIcon />,
                subMenu: null,
                hide: false
            },
            userGuide: {
                id: 'business.documents.type.userGuide.list.title',
                text: 'business.documents.type.userGuide.list.title',
                path: joinUrlWithParamsId(FRONT.DASHBOARD.DOCUMENTS, DocumentTypeEnum.userGuide.enumKey),
                icon: <ArticleIcon />,
                subMenu: null,
                hide: false
            },
        },
        hide: false
    }
}