import {LanguageDict} from "../types";
import dayjs, {Dayjs} from "dayjs";
import EventType from "./EventType";
import EventStatusEnum from "../enums/EventStatusEnum";
import ContributionStatusEnum from "../enums/ContributionStatusEnum";

export default class Event {
    public id: string
    public date: string
    public deadline: string
    public time: string
    public fees: number
    public place: string
    public nameEn: string
    public nameFr: string
    public lateTime: string
    public createdAt: Dayjs
    public unitFees: number
    public updatedAt: Dayjs
    public amountDue: number
    public penalityDue: number
    public platformFees: number
    public descriptionEn: string
    public descriptionFr: string
    public communities: string[]
    public type: EventType | null
    // public penalityDueDate: Dayjs
    public status: EventStatusEnum
    public amountCollected: number
    public latePenalityFees: number
    public forAllCommunities: boolean
    public absencePenalityFees: number
    public presenceStatus: EventStatusEnum | any
    public penalityPaymentStatus: ContributionStatusEnum | any

    constructor(data: any) {
        this.id = data.reference
        this.date = data.date
        this.deadline = data.deadline
        this.place = data.place
        // this.penalityDueDate = data.penalityDueDate
        this.latePenalityFees = data.latePenalityFees
        this.absencePenalityFees = data.absencePenalityFees
        this.status = EventStatusEnum.tryParse(data.status)
        this.presenceStatus = data.presenceStatus ? EventStatusEnum.tryParse(data.presenceStatus) : null
        this.penalityPaymentStatus = data.penalityPaymentStatus ? ContributionStatusEnum.tryParse(data.penalityPaymentStatus) : null
        this.descriptionEn = data.descriptionEn
        this.descriptionFr = data.description
        this.fees = data.fees
        this.penalityDue = data.penalityDue ?? 0
        this.amountCollected = data.amountCollected
        this.amountDue = data.amountDue
        this.nameEn = data.nameEn
        this.nameFr = data.nameFr
        this.time = data.time
        this.lateTime = data.lateTime
        this.platformFees = data.platformFees
        this.unitFees = data.unitFees
        this.forAllCommunities = data.forAllCommunities ?? false
        this.communities = data.communityReferences ?? []
        this.type = data.type ? new EventType(data.type) : null
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
    }

    getName = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`name${locale[0].toUpperCase()}${locale.substring(1)}`]
    }
    
    getDescription = (locale: LanguageDict): string => {
        // @ts-ignore
        return this[`description${locale[0].toUpperCase()}${locale.substring(1)}`]
    }
}
