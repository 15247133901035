import {Enumify} from "./Enumify";
import {formatMessageTypeToForm} from "../helpers/businessHelper";

export default class MessageTypeEnum extends Enumify<string> {
    static readonly email = new MessageTypeEnum('EMAIL');
    static readonly sms = new MessageTypeEnum('SMS');

    static readonly _ = this.closeEnum();

    constructor(value: string) {
        super();
        this.value = value;
    }

    get translated() {
        return formatMessageTypeToForm(this)
    }

    get transPrefix() {
        switch (this) {
            case MessageTypeEnum.sms:
                return 'messages.sms'
            case MessageTypeEnum.email:
            default:
                return 'messages.email'
        }
    }
}
