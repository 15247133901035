import EventStatusEnum from "../enums/EventStatusEnum";

export default class EventPresence {
    public id: string
    public community: string
    public responsibles: string[]
    public status: EventStatusEnum
    public communityReference: string
    public duration: number

    constructor(data: any) {
        this.id = data.reference
        this.duration = data.duration
        this.community = data.community
        this.responsibles = data.responsibles
        this.communityReference = data.communityReference
        this.status = EventStatusEnum.tryParse(data.status)
    }
}
